/* WeeklyMealCountForm.css */
.weekly-meal-count-form {
    font-family: Arial, sans-serif;
    width: 100%;
    margin: 20px auto;
    padding: 20px;
    max-width: 1200px;
    border: 1px solid #000;
  }
  
  .title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .meal-type {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .label {
    width: 120px; /* Adjust the width as necessary to fit your content */
    margin-right: 10px;
    text-align: left;
    font-weight: bold;
  }
  .countTextBox{
    max-width: 100px;
  }
  .inputs input {
    flex: 1;
    margin-right: 10px; /* Space between inputs */
    padding: 5px;
    border: 0px solid #ccc;
    text-align: center;
    border-radius: 10px;
    
  }

  .lunchBox, .breakfastBox{
    padding: 20px;
  }
  .lunchBox{
    background-color: rgb(147, 103, 236);
    color: white; 
  }

  .breakfastBox{
    background-color: rgb(23, 151, 111);
    color: white; 
  }
  .inputs input:last-child {
    
  }
  
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .label {
      margin-bottom: 5px;
    }
  
    .inputs {
      width: 100%;
    }
  }
  
  .day-header-row {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 5px;
  }
  
  .day-header {
    flex: 1;
    text-align: center;
    font-weight: bold;
  }
  
  .total-header {
    flex: 0 0 100px; /* Adjust the width of the 'Total' column as needed */
    text-align: center;
  }
  
  .row-with-total {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .total-input {
    flex: 0 0 100px; /* Adjust the width to match the 'Total' header */
    padding: 5px;
    margin-left: 10px; /* Spacing between last input and total */
    border: 1px solid #ccc;
  }
  
  .site-details-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(375px, 1fr));
    gap: 5px;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 2px solid black;
    padding-bottom: 10px;
  }
  
  .site-details-input {
    display: flex;
    border-radius: 10px;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 5px;
  }
  
  select, input {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
  }

  .site-details-input > label{
    font-weight: bold;
    text-align: left;
  }

  .site-details-input-signature{
    max-width: 500px;
    max-height: 250px;
    box-shadow: 0px -2px 5px black;
    background: #e7e7e7;
    text-align: center;
    margin: auto;
    position: relative;
  }

  button{
    border-radius: 10px !important;
    padding: 10px;
  }